body{
  font-family: 'Lato', sans-serif !important;
  margin: 0;
  padding: 0;
}
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
  float: left;
    width: 100%;
    clear: both;
    position: relative;
}
h1{
  font-weight: 700;
  font-size: 48px;
  color: #333;
}
 h2{
  font-weight: 700;
    font-size: 28px;
}
p{
  font-weight: 400;
  font-size: 16px;
  color: #444;
}
ul{
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
}
small.suggest {
  background: linear-gradient(95.71deg, #16AA51 32.15%, #4583E1 79.82%);
  border-radius: 4px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  padding: 2px 5px;
  margin-left: 8px;
}
small.most-exp {
  background: linear-gradient(95.71deg, #F3553E 45.28%, #FFC32A 74.96%);
  border-radius: 4px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  padding: 2px 5px;
  margin-left: 8px;
}
.truncate {
  display: inline-block;
  width: 1em;
  overflow: hidden;
  vertical-align: bottom;
  text-overflow: ellipsis;
}