@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;600&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inconsolata', monospace !important;
}
button#sart-build-2 svg {
  width: 12px;
  height: 12px;
}
button#sart-build-2 {
  border-radius: 7px;
  min-height: 56px;
  position: relative;
  font-size: 18px;
  font-weight: 700;
  padding: 0 25px;
  display: inline-flex;
  align-items: center;
  opacity: 1 !important;
}